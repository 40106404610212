<template>
  <div class="wrap">
    <div class="x-x Middle">
      <!-- 选择模板 -->
      <cardTitleCom cardTitle="选择模板" class="selectTemplate">
        <template slot="cardContent">
          <div class="padd10">
            <!-- 打印设置 -->
            <!-- <div class="x-bc printSettings">
              <span class="fontS14 example">打印设置</span>
              <span class="fontS14B cursorP">查看打印教程</span>
            </div> -->
            <!-- 打印模板 -->
            <div class="x-bc marB10">
              <span class="fontS14 example">打印模板：</span>
              <span class="fontS14B cursorP" @click="
                  $router.push('/goods/labelTemplate/labelTemplateDetail')
                ">新增</span>
            </div>
            <!-- 选择框 -->
            <div class="templateFrame cursorP x-fc" @click="openLabelTemplate = true">
              <div class="y-c" v-show="!labelTemplateForm">
                <i class="el-icon-plus annotateBlue marB20"></i>
                <span class="fontS14B">选择模板</span>
              </div>
              <div class="templateContent" v-if="labelTemplateForm">
                <!-- 标签样式 -->
                <div class="previewImg">
                  <div class="printDesignPreview" :style="{
                      width: labelTemplateForm.width + 'mm',
                      height: labelTemplateForm.height + 'mm',
                      'background-image': `url(${labelTemplateForm.backgroundUrl})`
                    }">
                    <div v-for="(item, index) in labelTemplateForm
                        .lableTemplateContent.labelList" :key="index" class="componentWrapper" :style="{
                        left: item.x + 'px',
                        top: item.y + 'px'
                      }">
                      <span v-show="
                          item.tagType != 'goodsBarcode' &&
                          item.tagType != 'customText' &&
                          item.tagType != 'qrCode'  &&
                          item.tagType != 'nutrient'
                        " :style="{
                          fontSize: item.fontSize + 'px',
                          fontWeight: item.bold ? 'bold' : 'normal',
                          textDecoration: item.underline ? 'underline' : 'none'
                        }">{{ item.printContent + ' ###' }}</span>
                      <div class="y-c" v-show="item.tagType == 'nutrient'">
                        <span :style="{
                      fontSize: item.fontSize + 'px',
                      fontWeight: item.bold ? 'bold' : 'normal',
                      textDecoration: item.underline ? 'underline' : 'none'
                    }">{{ item.printContent }}</span>
                        <div class="nutrient-table">
                          <table :style="{
                      fontSize: item.fontSize + 'px',
                      fontWeight: item.bold ? 'bold' : 'normal',
                      textDecoration: item.underline ? 'underline' : 'none'
                    }">
                            <thead>
                              <tr>
                                <th>{{item.labelName1}}</th>
                                <th>{{item.labelName2}}</th>
                                <th>{{item.labelName3}}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(nutrient, index) in nutrients" :key="index">
                                <td>{{ nutrient.nutrientName }}</td>
                                <td>{{ nutrient.nutrientQty }}</td>
                                <td>{{ nutrient.nutrientUnit }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <!-- 条形码 -->
                      <div v-if="item.tagType == 'goodsBarcode'">
                        <JsBarcode value="6901028071069" :displayValue="item.barcodeType != 1"
                          :width="item.barcodeWidth" :height="item.barcodeHeight" />
                      </div>
                      <!-- 自定义文字 -->
                      <span v-show="item.tagType == 'customText'" :style="{
                          fontSize: item.fontSize + 'px',
                          fontWeight: item.bold ? 'bold' : 'normal',
                          textDecoration: item.underline ? 'underline' : 'none'
                        }">
                        {{ item.customText }}
                      </span>
                      <!-- 二维码 -->
                      <el-image v-show="item.tagType == 'qrCode'" :style="{
                          width: item.imageWidth + 'mm',
                          height: item.imageHeight + 'mm'
                        }"
                        :src="item.qrCodeUrl||'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-05-22/8b326689866a48529f05bd1cfc50277b.png'" />
                    </div>
                  </div>
                </div>
                <!-- 第一行 -->
                <div class="marB10 x-bc">
                  <div class="omit templateName">
                    {{ labelTemplateForm.lableTemplateName }}
                  </div>
                  <div class="isSystem">自定义</div>
                </div>
                <!-- 第二行 -->
                <div class="marB10 x-bc">
                  <div>
                    {{
                      labelTemplateForm.width +
                      '*' +
                      labelTemplateForm.height +
                      'mm'
                    }}
                  </div>
                  <div>{{ labelTemplateForm.createTime }}</div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </cardTitleCom>

      <!-- 商品信息 -->
      <cardTitleCom cardTitle="商品信息" class="goodsInfo">
        <template slot="cardContent">
          <div class="tableContent">
            <div class="tableBtn">
              <div>
                <el-button type="primary" icon="el-icon-plus" size="mini"
                  @click="dialogOptions.show=true">添加商品</el-button>
                <el-button type="danger" icon="el-icon-delete" :disabled="multiple" @click="handDelete" size="mini">删除
                </el-button>
              </div>
              <!-- 右边盒子 -->
              <div>
                <!-- <el-button size="mini" icon="el-icon-download">导入</el-button>
                <el-button
                  size="mini"
                  icon="el-icon-upload2"
                  @click="$print('print-content')"
                  >导出</el-button
                > -->
                <el-button size="mini" icon="el-icon-printer" @click="handPrint"
                  :disabled="multiple || isBlur">打印</el-button>
                <el-button size="mini" class="marRBtn" icon="el-icon-refresh" myPrint @click="test($event)">刷新
                </el-button>
                <span ref="myPrint" v-print="'#print-content'"></span>
              </div>
            </div>
            <el-table v-loading="loadingTable" ref="multipleTable" @row-click="handleRowClick" tooltip-effect="dark"
              :data="tableData" @selection-change="handleSelectionChange" border max-height="589" height="589">
              <el-table-column type="selection" width="50" align="center" />
              <el-table-column label="序号" align="center" prop="userId" width="80" type="index" />
              <el-table-column show-overflow-tooltip label="商品编码" align="center" prop="goodsNo" />
              <el-table-column label="商品名称" align="center" prop="goodsName" />
              <el-table-column label="单位" align="center" prop="unitName">
                <template slot-scope="scope">
                  <el-select size="mini" v-model="scope.row.unitId" @change="unitPriceChange(scope.row)" filterable>
                    <el-option v-for="item in scope.row.units" :key="item.unitId" :label="item.unitName"
                      :value="item.unitId" />
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column label="零售价（元）" align="center" prop="salePrice" />
              <el-table-column label="打印份数" align="center" prop="printCount">
                <template slot-scope="scope">
                  <el-input @click.native.stop v-model="scope.row.printCount" @focus="handleFocus"
                    @blur="handleBlur(scope.row.printCount, scope.row.goodsId)" />
                </template>
              </el-table-column>
            </el-table>
            <bottomPage v-model="queryParams" :getList="getList" :totalCom="total" :isSelectAll="false" />
          </div>
        </template>
      </cardTitleCom>
    </div>

    <!-- 标签样式 -->
    <div id="print-content">
      <div v-for="(item, index) in printList" :key="index" style="page-break-after: always" class="printBody">
        <div class="printTemplateContent">
          <!-- 标签样式 -->
          <div class="previewImg">
            <div class="printDesignPreview print-content" :style="{
                width: item.width + 'mm',
                height: item.height + 'mm',
                'background-image': `url(${item.backgroundUrl})`
              }">
              <div v-for="(each, index) in item.lableTemplateContent.labelList" :key="index" class="componentWrapper"
                :style="{
                  left: each.x + 'px',
                  top: each.y + 'px'
                }">
                <span v-show="
                    each.printTagType != 'GoodsBarcode' &&
                    each.printTagType != 'CustomText' &&
                    each.printTagType != 'QrCode'  &&
                    each.printTagType != 'Nutrient'
                  " :style="{
                    fontSize: each.fontSize + 'px',
                    fontWeight: each.bold ? 'bold' : 'normal',
                    textDecoration: each.underline ? 'underline' : 'none'
                  }">{{
                    each.printContentType == 1
                      ? each.printContent + each.tagType
                      : each.tagType
                  }}</span>
                <div class="y-c" v-show="each.printTagType == 'Nutrient'">
                  <span :style="{
                      fontSize: each.fontSize + 'px',
                      fontWeight: each.bold ? 'bold' : 'normal',
                      textDecoration: each.underline ? 'underline' : 'none'
                    }">{{ each.printContent }}</span>
                  <div class="nutrient-table">
                    <table :style="{
                      fontSize: each.fontSize + 'px',
                      fontWeight: each.bold ? 'bold' : 'normal',
                      textDecoration: each.underline ? 'underline' : 'none'
                    }">
                      <thead>
                        <tr>
                          <th>{{each.labelName1}}</th>
                          <th>{{each.labelName2}}</th>
                          <th>{{each.labelName3}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(nutrient, index) in each.tagType" :key="index">
                          <td>{{ nutrient.nutrientName }}</td>
                          <td>{{ nutrient.nutrientQty }}</td>
                          <td>{{ nutrient.nutrientUnit }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!-- 条形码 -->
                <div v-if="each.printTagType == 'GoodsBarcode'">
                  <JsBarcode v-if="each.printTagType == 'GoodsBarcode'" :value="each.tagType" :width="each.barcodeWidth"
                    :height="each.barcodeHeight" />
                  <!-- <span>{{ each.tagType }}</span> -->
                </div>

                <!-- 自定义文字 -->
                <span v-show="each.printTagType == 'CustomText'" :style="{
                    fontSize: each.fontSize + 'px',
                    fontWeight: each.bold ? 'bold' : 'normal',
                    textDecoration: each.underline ? 'underline' : 'none'
                  }">
                  {{ each.customText }}
                </span>
                <!-- 二维码 -->
                <img v-show="each.printTagType == 'QrCode'" :style="{
                    width: each.imageWidth + 'mm',
                    height: each.imageHeight + 'mm'
                  }" :src="qrCodeUrl" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 标签 -->
    <selectLabelTemplate :OpenLabelTemplate.sync="openLabelTemplate" @labelTemplateCom="labelTemplateCom" />

    <Dialog ref="tablePage" :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import Dialog from "@/components/Dialog";

import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import selectLabelTemplate from '@/views/components/selectLabelTemplate' //标签模板
import { labelTemplateListAPI } from '@/api/system/goods/labelTemplate' //模板
import {
  labelPrintListAPI,
  labelPrintAddAPI,
  labelPrintDeleteAPI,
  labelPrintAPI
} from '@/api/system/goods/labelPrint' //价签
import JsBarcode from '@/views/components/jsbarcode'
import bottomPage from '@/views/components/bottomPage' //底部分页
import { deepCopy } from '@/utils';
export default {
  name: 'labelPrint',
  components: {
    JsBarcode,
    cardTitleCom,
    selectLabelTemplate,
    bottomPage,
    Dialog,
  },
  data() {
    return {
      nutrients: [
        {
          nutrientName: '蛋白质',
          nutrientQty: '8.8克(g)',
          nutrientUnit: '15%'
        },
        {
          nutrientName: '碳水化合物',
          nutrientQty: '25.8克(g)',
          nutrientUnit: '9%'
        },
        {
          nutrientName: '脂肪',
          nutrientQty: '62.2克(g)',
          nutrientUnit: '104%'
        },
        // 添加更多营养物质...
      ],
      //弹窗配置
      dialogOptions: {
        title: "选择商品",
        width: 1250,
        show: false,
        type: "TreeAndTable",
        formData: this.$dialog({ key: "goods" }),
      },
      qrCodeUrl: '', //二维码
      printList: [], //打印
      labelTemplateList: [], //模版
      ids: [], // 选中id
      total: 0, // 总条数
      tableData: [], //表格数据
      tableDataOld: [], //表格数据对比
      //表格的加载
      loadingTable: false,
      //模板
      openLabelTemplate: false,
      multiple: true, // 非单个禁用
      labelTemplateForm: undefined, //选择的模板
      //分页查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15
      },
      isBlur: false
    }
  },
  async created() {
    //标签模版
    this.labelTemplateList = await labelTemplateListAPI()
    await this.getList()
  },
  methods: {
    //打印
    async handPrint() {
      const res = await labelPrintAPI(this.ids)
      res.data.forEach(item => {
        const newObj = JSON.parse(item.lableTemplateContent)
        newObj.labelList.forEach(v => {
          if (v.printTagType == 'Nutrient') {
            console.log('vvv', v);
            v.tagType = JSON.parse(v.tagType)
          }
        })
        this.$set(item, 'lableTemplateContent', newObj)
      })
      this.printList = res.data
      console.log('printList', deepCopy(this.printList))
      await this.$nextTick()
      this.getOpenPrint()

      // this.$print('#print-content')
      // this.$print('.print-content')
      // Print.printJS({
      //   printable: 'print-content',
      //   type: 'html'
      // })
      console.log('res', res)
    },
    //模拟点击
    async getOpenPrint() {
      // 获取 input 框元素
      const myPrint = this.$refs.myPrint
      // 确保元素已经被添加到 DOM 树中
      await this.$nextTick()
      // 创建一个模拟点击事件
      const e = document.createEvent('MouseEvents')
      e.initEvent('click', true, true)
      // 触发模拟点击事件
      myPrint.dispatchEvent(e)
    },
    async handleEvent(type, row) {
      switch (type) {
        case "dialogChange":
          this.getGoodS(this.dialogOptions.formData.table.check)
          break;
        default:
          break;
      }
    },
    //删除事件
    handDelete() {
      this.$confirm('此操作将永久删除所选商品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await labelPrintDeleteAPI(this.ids)
          await this.getList()
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        })
        .catch(() => { })
    },
    //输入框失焦事件
    handleBlur(printCount, goodsId) {
      this.tableDataOld.forEach(async item => {
        if (item.goodsId == goodsId && item.printCount != printCount) {
          await labelPrintAddAPI(this.tableData)
          await this.getList()
          this.isBlur = false
          this.$message({
            message: '修改成功',
            type: 'success'
          })
        }
      })
    },
    handleFocus() {
      console.log('1')

      this.isBlur = true
    },
    //选中的商品
    async getGoodS(value) {
      if (value.length == 0) return
      value = value.map(v => {
        const obj = {
          goodsId: v.goodsId,
          isDefaultInventory: false,
          lableTemplateId: this.labelTemplateForm
            ? this.labelTemplateForm.lableTemplateId
            : undefined,
          printCount: 1
        }
        return obj
      })
      await labelPrintAddAPI(value)
      this.$message({
        message: '新增成功',
        type: 'success'
      })
      await this.getList()
    },
    //模板导入
    async labelTemplateCom(value) {
      this.labelTemplateForm = value
      if (this.tableData.length > 0) {
        this.tableData.forEach(item => {
          this.$set(
            item,
            'lableTemplateId',
            this.labelTemplateForm.lableTemplateId
          )
        })
        await labelPrintAddAPI(this.tableData)
        await this.getList()
      }
      this.$message({
        message: '更换模版成功',
        type: 'success'
      })
    },
    //渲染列表
    async getList() {
      this.loadingTable = true
      const res = await labelPrintListAPI(this.queryParams)
      this.tableData = res.rows
      this.total = res.total
      this.tableDataOld = JSON.parse(JSON.stringify(this.tableData))
      if (this.labelTemplateList.length > 0 && this.tableData.length > 0) {
        for (let i = 0; i < this.labelTemplateList.length; i++) {
          const item = this.labelTemplateList[i];
          if (item.lableTemplateId === this.tableData[0].lableTemplateId) {
            const oldForm = JSON.parse(JSON.stringify(item));
            const newObj = JSON.parse(oldForm.lableTemplateContent);
            // 注意：在 Vue 中，如果 oldForm 是响应式的，直接使用 oldForm.lableTemplateContent = newObj 通常就足够
            // 但如果你想要确保它触发视图更新，你可以使用 this.$set
            this.$set(oldForm, 'lableTemplateContent', newObj);
            this.labelTemplateForm = oldForm;
            // 如果你只想找到第一个匹配的项并退出循环，可以加上 break
            break;
          }
        }
        let qrCodeUrls = this.labelTemplateForm.lableTemplateContent.labelList
          .filter(item => item.tagType === 'qrCode' && item.qrCodeUrl)
          .map(item => item.qrCodeUrl);
        this.qrCodeUrl = qrCodeUrls?.length > 0 ? qrCodeUrls[0] : ''
      }

      this.loadingTable = false
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => ({ goodsId: item.goodsId, unitId: item.unitId })) //单据id
      this.multiple = !selection.length
    },
    //刷新按钮
    test(e) {
      let target = e.target
      if (target.nodeName == 'I' || target.nodeName == 'SPAN') {
        target = e.target.parentNode
      }
      target.blur()
      this.getList()
      this.$message({
        message: '刷新成功',
        type: 'success'
      })
    },
    //点击行选中
    handleRowClick(row) {
      // 获取表格对象
      // 切换行选中状态
      this.$refs.multipleTable.toggleRowSelection(row)
    },
    unitPriceChange(row) {
      let obj = row.units.find((v) => v.unitId == row.unitId);
      this.$set(row, "salePrice", obj.unitPrice);
      for (let i = 0; i < this.ids.length; i++) {
        if (x.goodsId === row.goodsId) {
          x.unitId = row.unitId;
          break;
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  padding: 10px 10px 1px 10px;
  background-color: #eaeaea;
  //中间
  .Middle {
    height: 100%;
    width: 100%;
    //选择模板
    .selectTemplate {
      width: 350px;
      display: flex;
      justify-content: center;
      //打印设置
      .printSettings {
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid #ebedf0;
      }
      //模板框
      .templateFrame {
        width: 290px;
        height: 237px;

        border: 1px dashed #1890ff;
        //模版内容
        .templateContent {
          position: relative;
          width: 290px;
          padding: 15px 15px 1px;
          background: #f7f8fa;
          border: 1px solid #dcdee0;
          box-sizing: border-box;
          border-radius: 2px;
          font-size: 14px;
          //背景
          .previewImg {
            background: #fff;
            max-height: 40mm;
            max-width: 60mm;
            margin-left: 15px;
            margin-bottom: 15px;
            overflow: hidden;
            .printDesignPreview {
              transform-origin: left top;
              display: flex;
              background-size: cover;
              background-repeat: no-repeat;
              border: 1px solid #ebedf0;
              position: relative;
              overflow: hidden;

              .componentWrapper {
                position: absolute;
                line-height: 1;
              }
            }
          }
          //模版名字
          .templateName {
            width: calc(100% - 52px);
          }
          //是否系统
          .isSystem {
            color: rgb(23, 101, 217);
            background-color: rgb(237, 244, 255);
            border: 1px solid rgb(84, 135, 223);
            line-height: 18px;
            height: 18px;
            box-sizing: border-box;
            padding: 0 4px;
            min-width: auto;
            border-radius: 2px;
          }
        }
      }
    }
    //商品信息
    .goodsInfo {
      margin-left: 10px;
      flex: 1;
      //主题内容 按钮表格
      .tableContent {
        margin: 6px 10px 10px 10px;
        .tableBtn {
          height: 50px;
          line-height: 50px;
          background-color: #f9f9fa;
          padding-left: 5px;
          margin-bottom: 6px;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}
@media print {
  @page {
    size: auto; /* 打印纸张大小 */
    margin: 0;
    padding: 0;
  }
  html,
  body {
    height: auto !important;
  }
  /* 设置背景图片为打印图片 */
  .print-content {
    -webkit-print-color-adjust: exact;
    -moz-print-color-adjust: exact;
    color-adjust: exact;
    // background-image: url('https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-05-23/796e0eca32fc4c14875a28d2426c401e.png');
    background-size: cover;
    visibility: visible !important;
  }
}
.printBody {
  // display: inline-block;
  background-color: #fff;
  //模版内容
  .printTemplateContent {
    position: relative;
    background: #f7f8fa;
    box-sizing: border-box;
    border-radius: 2px;
    //背景
    .previewImg {
      background-repeat: no-repeat;
      background: #fff;
      overflow: hidden;
      .printDesignPreview {
        display: flex;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        overflow: hidden;
        .componentWrapper {
          position: absolute;
          line-height: 1;
        }
      }
    }
  }
}
.nutrient-table {
  border: 1px solid #000000; /* 外层边框 */
  table {
    width: 100%;
    border-collapse: collapse; /* 合并相邻的边框 */
  }
  th {
    border-bottom: 1px solid #000000; /* 单元格底部边框 */
  }
  td:first-child,
  th:first-child {
    text-align: left; /* 第一个td和th文本左对齐 */
  }
  td:nth-child(2),
  td:nth-child(3),
  th:nth-child(2),
  th:nth-child(3) {
    text-align: right; /* 第二个和第三个td和th文本右对齐 */
  }
  td:nth-child(2),
  th:nth-child(2) {
    padding: 0 10px;
  }
}
</style>
