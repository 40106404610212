<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent"> </TablePage>
    <!-- 弹窗 -->
    <Dialog :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import TablePage from "@/components/tablePage"; //表格页面组件
import Dialog from "@/components/Dialog";
import {
  goodsPackingList,
  goodsPackingDel,
  goodsPackingRevert,
} from "@/api/system/goods/packing";

export default {
  name: "Packing",
  components: { TablePage, Dialog },
  data() {
    return {
      options: {
        mutiSelect: true, // 多选
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        title: "商品包装列表",
        getListApi: goodsPackingList,
        listNo: true, // 序号
        check: [], // 选中数据
        rowKey: "packingId",
        //搜索
        search: [
          {
            type: "filters",
            tip: "包装编码/包装名称",
            isRestore: true,
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "packingNos", label: "包装编码" },
              { filter: "packingNames", label: "包装名称" },
            ],
          },
          {
            type: "button",
            tip: "查询",
            btnType: "primary",
            click: "search",
            isRestore: true,
          },
        ],
        //按钮
        buttons: [
          {
            click: "add",
            label: "新增",
            icon: "el-icon-plus",
            type: "primary",
          },
          {
            click: "del",
            label: "删除",
            type: "danger",
            icon: "el-icon-delete",
            alertText: "确认要删除选中商品包装吗？",
            btnType: "dropdown",
            trigger: "click",
            other: [
              {
                label: "回收站",
                collectionDialog: {
                  restoreListApi: goodsPackingRevert,
                  dcollectionBody: {
                    delFlag: "1",
                  },
                },
              },
            ],
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        //表格
        columns: [
          {
            prop: "packingNo",
            label: "包装编号",
            type: "link",
            click: "update",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "packingName",
            label: "包装名称",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "packingWeight",
            label: "包装重量",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "remark",
            label: "备注",
            align: "center",
          },
        ],
      },
      //弹窗配置
      dialogOptions: {
        title: "新增包装",
        width: 600,
        show: false,
        type: "Packing",
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      let billIds = [];
      switch (type) {
        case "add":
          //弹窗配置
          this.dialogOptions = {
            title: "新增包装",
            width: 600,
            show: true,
            click: "add",
            type: "Packing",
          };
          break;
        case "update":
          this.dialogOptions = {
            title: "编辑包装",
            width: 600,
            show: true,
            click: "update",
            type: "Packing",
            formData: row,
          };
          break;
        case "del":
          let packingIds = [];
          this.options.check.forEach((item) => {
            packingIds.push(item.packingId);
          });
          try {
            await goodsPackingDel(packingIds);
            this.$message.success("删除成功");
            this.$refs.tablePage.getList();
          } catch (err) {}
          break;
        case "dialogChange":
          this.$refs.tablePage.getList();
          break;
        default:
          break;
      }
    },
  },
};
</script>
