var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c(
        "div",
        { staticClass: "x-x Middle" },
        [
          _c(
            "cardTitleCom",
            { staticClass: "selectTemplate", attrs: { cardTitle: "选择模板" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c("div", { staticClass: "padd10" }, [
                  _c("div", { staticClass: "x-bc marB10" }, [
                    _c("span", { staticClass: "fontS14 example" }, [
                      _vm._v("打印模板："),
                    ]),
                    _c(
                      "span",
                      {
                        staticClass: "fontS14B cursorP",
                        on: {
                          click: function ($event) {
                            return _vm.$router.push(
                              "/goods/labelTemplate/labelTemplateDetail"
                            )
                          },
                        },
                      },
                      [_vm._v("新增")]
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "templateFrame cursorP x-fc",
                      on: {
                        click: function ($event) {
                          _vm.openLabelTemplate = true
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.labelTemplateForm,
                              expression: "!labelTemplateForm",
                            },
                          ],
                          staticClass: "y-c",
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-plus annotateBlue marB20",
                          }),
                          _c("span", { staticClass: "fontS14B" }, [
                            _vm._v("选择模板"),
                          ]),
                        ]
                      ),
                      _vm.labelTemplateForm
                        ? _c("div", { staticClass: "templateContent" }, [
                            _c("div", { staticClass: "previewImg" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "printDesignPreview",
                                  style: {
                                    width: _vm.labelTemplateForm.width + "mm",
                                    height: _vm.labelTemplateForm.height + "mm",
                                    "background-image":
                                      "url(" +
                                      _vm.labelTemplateForm.backgroundUrl +
                                      ")",
                                  },
                                },
                                _vm._l(
                                  _vm.labelTemplateForm.lableTemplateContent
                                    .labelList,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "componentWrapper",
                                        style: {
                                          left: item.x + "px",
                                          top: item.y + "px",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  item.tagType !=
                                                    "goodsBarcode" &&
                                                  item.tagType !=
                                                    "customText" &&
                                                  item.tagType != "qrCode" &&
                                                  item.tagType != "nutrient",
                                                expression:
                                                  "\n                        item.tagType != 'goodsBarcode' &&\n                        item.tagType != 'customText' &&\n                        item.tagType != 'qrCode'  &&\n                        item.tagType != 'nutrient'\n                      ",
                                              },
                                            ],
                                            style: {
                                              fontSize: item.fontSize + "px",
                                              fontWeight: item.bold
                                                ? "bold"
                                                : "normal",
                                              textDecoration: item.underline
                                                ? "underline"
                                                : "none",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(item.printContent + " ###")
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  item.tagType == "nutrient",
                                                expression:
                                                  "item.tagType == 'nutrient'",
                                              },
                                            ],
                                            staticClass: "y-c",
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                style: {
                                                  fontSize:
                                                    item.fontSize + "px",
                                                  fontWeight: item.bold
                                                    ? "bold"
                                                    : "normal",
                                                  textDecoration: item.underline
                                                    ? "underline"
                                                    : "none",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(item.printContent)
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "nutrient-table" },
                                              [
                                                _c(
                                                  "table",
                                                  {
                                                    style: {
                                                      fontSize:
                                                        item.fontSize + "px",
                                                      fontWeight: item.bold
                                                        ? "bold"
                                                        : "normal",
                                                      textDecoration:
                                                        item.underline
                                                          ? "underline"
                                                          : "none",
                                                    },
                                                  },
                                                  [
                                                    _c("thead", [
                                                      _c("tr", [
                                                        _c("th", [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.labelName1
                                                            )
                                                          ),
                                                        ]),
                                                        _c("th", [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.labelName2
                                                            )
                                                          ),
                                                        ]),
                                                        _c("th", [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.labelName3
                                                            )
                                                          ),
                                                        ]),
                                                      ]),
                                                    ]),
                                                    _c(
                                                      "tbody",
                                                      _vm._l(
                                                        _vm.nutrients,
                                                        function (
                                                          nutrient,
                                                          index
                                                        ) {
                                                          return _c(
                                                            "tr",
                                                            { key: index },
                                                            [
                                                              _c("td", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    nutrient.nutrientName
                                                                  )
                                                                ),
                                                              ]),
                                                              _c("td", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    nutrient.nutrientQty
                                                                  )
                                                                ),
                                                              ]),
                                                              _c("td", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    nutrient.nutrientUnit
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        item.tagType == "goodsBarcode"
                                          ? _c(
                                              "div",
                                              [
                                                _c("JsBarcode", {
                                                  attrs: {
                                                    value: "6901028071069",
                                                    displayValue:
                                                      item.barcodeType != 1,
                                                    width: item.barcodeWidth,
                                                    height: item.barcodeHeight,
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  item.tagType == "customText",
                                                expression:
                                                  "item.tagType == 'customText'",
                                              },
                                            ],
                                            style: {
                                              fontSize: item.fontSize + "px",
                                              fontWeight: item.bold
                                                ? "bold"
                                                : "normal",
                                              textDecoration: item.underline
                                                ? "underline"
                                                : "none",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.customText) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c("el-image", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: item.tagType == "qrCode",
                                              expression:
                                                "item.tagType == 'qrCode'",
                                            },
                                          ],
                                          style: {
                                            width: item.imageWidth + "mm",
                                            height: item.imageHeight + "mm",
                                          },
                                          attrs: {
                                            src:
                                              item.qrCodeUrl ||
                                              "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-05-22/8b326689866a48529f05bd1cfc50277b.png",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              ),
                            ]),
                            _c("div", { staticClass: "marB10 x-bc" }, [
                              _c("div", { staticClass: "omit templateName" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.labelTemplateForm.lableTemplateName
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("div", { staticClass: "isSystem" }, [
                                _vm._v("自定义"),
                              ]),
                            ]),
                            _c("div", { staticClass: "marB10 x-bc" }, [
                              _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.labelTemplateForm.width +
                                        "*" +
                                        _vm.labelTemplateForm.height +
                                        "mm"
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("div", [
                                _vm._v(
                                  _vm._s(_vm.labelTemplateForm.createTime)
                                ),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { staticClass: "goodsInfo", attrs: { cardTitle: "商品信息" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "tableContent" },
                  [
                    _c("div", { staticClass: "tableBtn" }, [
                      _c(
                        "div",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-plus",
                                size: "mini",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.dialogOptions.show = true
                                },
                              },
                            },
                            [_vm._v("添加商品")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "danger",
                                icon: "el-icon-delete",
                                disabled: _vm.multiple,
                                size: "mini",
                              },
                              on: { click: _vm.handDelete },
                            },
                            [_vm._v("删除 ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                size: "mini",
                                icon: "el-icon-printer",
                                disabled: _vm.multiple || _vm.isBlur,
                              },
                              on: { click: _vm.handPrint },
                            },
                            [_vm._v("打印")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "marRBtn",
                              attrs: {
                                size: "mini",
                                icon: "el-icon-refresh",
                                myPrint: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.test($event)
                                },
                              },
                            },
                            [_vm._v("刷新 ")]
                          ),
                          _c("span", {
                            directives: [
                              {
                                name: "print",
                                rawName: "v-print",
                                value: "#print-content",
                                expression: "'#print-content'",
                              },
                            ],
                            ref: "myPrint",
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loadingTable,
                            expression: "loadingTable",
                          },
                        ],
                        ref: "multipleTable",
                        attrs: {
                          "tooltip-effect": "dark",
                          data: _vm.tableData,
                          border: "",
                          "max-height": "589",
                          height: "589",
                        },
                        on: {
                          "row-click": _vm.handleRowClick,
                          "selection-change": _vm.handleSelectionChange,
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            type: "selection",
                            width: "50",
                            align: "center",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "序号",
                            align: "center",
                            prop: "userId",
                            width: "80",
                            type: "index",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            "show-overflow-tooltip": "",
                            label: "商品编码",
                            align: "center",
                            prop: "goodsNo",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "商品名称",
                            align: "center",
                            prop: "goodsName",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "单位",
                            align: "center",
                            prop: "unitName",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { size: "mini", filterable: "" },
                                      on: {
                                        change: function ($event) {
                                          return _vm.unitPriceChange(scope.row)
                                        },
                                      },
                                      model: {
                                        value: scope.row.unitId,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "unitId", $$v)
                                        },
                                        expression: "scope.row.unitId",
                                      },
                                    },
                                    _vm._l(scope.row.units, function (item) {
                                      return _c("el-option", {
                                        key: item.unitId,
                                        attrs: {
                                          label: item.unitName,
                                          value: item.unitId,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "零售价（元）",
                            align: "center",
                            prop: "salePrice",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "打印份数",
                            align: "center",
                            prop: "printCount",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    on: {
                                      focus: _vm.handleFocus,
                                      blur: function ($event) {
                                        return _vm.handleBlur(
                                          scope.row.printCount,
                                          scope.row.goodsId
                                        )
                                      },
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                      },
                                    },
                                    model: {
                                      value: scope.row.printCount,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "printCount", $$v)
                                      },
                                      expression: "scope.row.printCount",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _c("bottomPage", {
                      attrs: {
                        getList: _vm.getList,
                        totalCom: _vm.total,
                        isSelectAll: false,
                      },
                      model: {
                        value: _vm.queryParams,
                        callback: function ($$v) {
                          _vm.queryParams = $$v
                        },
                        expression: "queryParams",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { id: "print-content" } },
        _vm._l(_vm.printList, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "printBody",
              staticStyle: { "page-break-after": "always" },
            },
            [
              _c("div", { staticClass: "printTemplateContent" }, [
                _c("div", { staticClass: "previewImg" }, [
                  _c(
                    "div",
                    {
                      staticClass: "printDesignPreview print-content",
                      style: {
                        width: item.width + "mm",
                        height: item.height + "mm",
                        "background-image": "url(" + item.backgroundUrl + ")",
                      },
                    },
                    _vm._l(
                      item.lableTemplateContent.labelList,
                      function (each, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "componentWrapper",
                            style: {
                              left: each.x + "px",
                              top: each.y + "px",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      each.printTagType != "GoodsBarcode" &&
                                      each.printTagType != "CustomText" &&
                                      each.printTagType != "QrCode" &&
                                      each.printTagType != "Nutrient",
                                    expression:
                                      "\n                  each.printTagType != 'GoodsBarcode' &&\n                  each.printTagType != 'CustomText' &&\n                  each.printTagType != 'QrCode'  &&\n                  each.printTagType != 'Nutrient'\n                ",
                                  },
                                ],
                                style: {
                                  fontSize: each.fontSize + "px",
                                  fontWeight: each.bold ? "bold" : "normal",
                                  textDecoration: each.underline
                                    ? "underline"
                                    : "none",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    each.printContentType == 1
                                      ? each.printContent + each.tagType
                                      : each.tagType
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: each.printTagType == "Nutrient",
                                    expression:
                                      "each.printTagType == 'Nutrient'",
                                  },
                                ],
                                staticClass: "y-c",
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    style: {
                                      fontSize: each.fontSize + "px",
                                      fontWeight: each.bold ? "bold" : "normal",
                                      textDecoration: each.underline
                                        ? "underline"
                                        : "none",
                                    },
                                  },
                                  [_vm._v(_vm._s(each.printContent))]
                                ),
                                _c("div", { staticClass: "nutrient-table" }, [
                                  _c(
                                    "table",
                                    {
                                      style: {
                                        fontSize: each.fontSize + "px",
                                        fontWeight: each.bold
                                          ? "bold"
                                          : "normal",
                                        textDecoration: each.underline
                                          ? "underline"
                                          : "none",
                                      },
                                    },
                                    [
                                      _c("thead", [
                                        _c("tr", [
                                          _c("th", [
                                            _vm._v(_vm._s(each.labelName1)),
                                          ]),
                                          _c("th", [
                                            _vm._v(_vm._s(each.labelName2)),
                                          ]),
                                          _c("th", [
                                            _vm._v(_vm._s(each.labelName3)),
                                          ]),
                                        ]),
                                      ]),
                                      _c(
                                        "tbody",
                                        _vm._l(
                                          each.tagType,
                                          function (nutrient, index) {
                                            return _c("tr", { key: index }, [
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(nutrient.nutrientName)
                                                ),
                                              ]),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(nutrient.nutrientQty)
                                                ),
                                              ]),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(nutrient.nutrientUnit)
                                                ),
                                              ]),
                                            ])
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                            each.printTagType == "GoodsBarcode"
                              ? _c(
                                  "div",
                                  [
                                    each.printTagType == "GoodsBarcode"
                                      ? _c("JsBarcode", {
                                          attrs: {
                                            value: each.tagType,
                                            width: each.barcodeWidth,
                                            height: each.barcodeHeight,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: each.printTagType == "CustomText",
                                    expression:
                                      "each.printTagType == 'CustomText'",
                                  },
                                ],
                                style: {
                                  fontSize: each.fontSize + "px",
                                  fontWeight: each.bold ? "bold" : "normal",
                                  textDecoration: each.underline
                                    ? "underline"
                                    : "none",
                                },
                              },
                              [_vm._v(" " + _vm._s(each.customText) + " ")]
                            ),
                            _c("img", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: each.printTagType == "QrCode",
                                  expression: "each.printTagType == 'QrCode'",
                                },
                              ],
                              style: {
                                width: each.imageWidth + "mm",
                                height: each.imageHeight + "mm",
                              },
                              attrs: { src: _vm.qrCodeUrl },
                            }),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ]),
              ]),
            ]
          )
        }),
        0
      ),
      _c("selectLabelTemplate", {
        attrs: { OpenLabelTemplate: _vm.openLabelTemplate },
        on: {
          "update:OpenLabelTemplate": function ($event) {
            _vm.openLabelTemplate = $event
          },
          "update:open-label-template": function ($event) {
            _vm.openLabelTemplate = $event
          },
          labelTemplateCom: _vm.labelTemplateCom,
        },
      }),
      _c("Dialog", {
        ref: "tablePage",
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.handleEvent,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }