var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap", attrs: { id: "wrap" } },
    [
      _c(
        "cardTitleCom",
        { attrs: { cardTitle: "查询", id: "search-card" } },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              { staticClass: "navSearch" },
              [
                _c("seniorSearch", {
                  attrs: {
                    getList: _vm.getList,
                    dropDownList: _vm.dropDownList,
                    isAdvancedSearch: false,
                    isBillDate: false,
                    isSearchInput: true,
                    isSearchInputTitle: "输入属性组名称、属性名称",
                  },
                  model: {
                    value: _vm.queryParams,
                    callback: function ($$v) {
                      _vm.queryParams = $$v
                    },
                    expression: "queryParams",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "cardTitleCom",
        { staticClass: "brandInfo", attrs: { cardTitle: "属性汇总" } },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              { staticClass: "tableContent" },
              [
                _c("operatingButton", {
                  attrs: {
                    getList: _vm.getList,
                    isRecycleBtn: true,
                    isDeleteBtn: false,
                    multiple: _vm.multiple,
                  },
                  on: {
                    handleAdd: _vm.handleAdd,
                    handleDelete: _vm.handleDelete,
                    handleRedoDelete: function ($event) {
                      _vm.dialogOptions.show = true
                    },
                  },
                }),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loadingTable,
                        expression: "loadingTable",
                      },
                    ],
                    ref: "multipleTable",
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.tableData,
                      "tooltip-effect": "dark",
                      border: "",
                      "max-height": _vm.tableHeight,
                      height: _vm.tableHeight,
                      "row-key": "machiningGroupId",
                    },
                    on: {
                      "selection-change": _vm.handleSelectionChange,
                      "row-click": _vm.handleRowClick,
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "selection",
                        width: "50",
                        align: "center",
                        fixed: "left",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "序号",
                        align: "center",
                        type: "index",
                        width: "50",
                        fixed: "left",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "属性组名称",
                        align: "center",
                        prop: "machiningGroupName",
                        "show-overflow-tooltip": "",
                        width: "400",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-link",
                                {
                                  attrs: { type: "primary", target: "_blank" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.handleUpdate(scope.row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(scope.row.machiningGroupName) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "属性名称",
                        align: "center",
                        prop: "machiningName",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "关联商品数",
                        align: "center",
                        prop: "machiningCount",
                        "show-overflow-tooltip": "",
                        width: "250",
                      },
                    }),
                  ],
                  1
                ),
                _c("bottomPage", {
                  attrs: {
                    getList: _vm.getList,
                    totalCom: _vm.total,
                    idsCom: _vm.machiningGroupIds,
                  },
                  on: { selectAllCom: _vm.selectAll },
                  model: {
                    value: _vm.queryParams,
                    callback: function ($$v) {
                      _vm.queryParams = $$v
                    },
                    expression: "queryParams",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("machiningDetail", {
        attrs: {
          open: _vm.OpenMachiningDetail,
          machiningGroupId: _vm.machiningGroupId,
        },
        on: {
          "update:open": function ($event) {
            _vm.OpenMachiningDetail = $event
          },
          getList: _vm.getList,
        },
      }),
      _c("Dialog2", {
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.handleEvent,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }