<template>
  <!-- 属性明细 -->
  <div class="machiningDetail">
    <c-dialog :levelType="true" title="加值属性" :width="1000" :showDialog.sync="open" @close="handleClose">
      <template #content>
        <el-form :model="form" :rules="rules" ref="form" label-width="100px">
          <cardTitleCom cardTitle="属性项信息">
            <template #cardContent>
              <div class="main" style="padding:10px">
                <el-form-item label="属性组名称" class="x-f" prop="machiningGroupName">
                  <el-input v-model="form.machiningGroupName" placeholder="请输入属性组名称"></el-input>
                </el-form-item>
                <el-form-item label="属性值多选" prop="isEnableCheckBox">
                  <el-checkbox v-model="form.isEnableCheckBox">启用</el-checkbox>
                  <div class="tag">
                    该属性支持选择多个属性值,比如一杯奶茶可以添加布丁,珍珠等多种配料.
                  </div>
                </el-form-item>
                <el-form-item label="属性值必选" prop="isRequired">
                  <el-checkbox v-model="form.isRequired">启用</el-checkbox>
                  <div class="tag">启用后,该属性为用户下单必选内容.</div>
                </el-form-item>
              </div>
            </template>
          </cardTitleCom>
          <cardTitleCom cardTitle="属性列表">
            <template #leftCardTitle>
              <el-button type="primary" size="mini" @click="huiShou">回收站</el-button>
            </template>
            <template #cardContent>
              <div style="padding:10px">
                <!-- 表格区域 -->
                <el-table class="table" ref="Table" :data="form.machiningItems" row-key="columnId" max-height="300"
                  border @cell-mouse-enter="cellMouseEnter" @cell-mouse-leave="cellMouseLeave">
                  <el-table-column align="center" :label="tableCellLabel" width="80">
                    <template v-slot="scope">
                      <i v-if="scope.row.hoverRow" @click="handleRow('push', scope.$index)"
                        class="el-icon-circle-plus operatePush"></i>
                      <i v-if="scope.row.hoverRow" @click="handleRow('del', scope.$index)"
                        class="el-icon-remove operateDel"></i>
                      <div v-if="!scope.row.hoverRow">{{ scope.$index + 1 }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column v-for="item in tableField" :key="item.prop" :label="item.label" align="center"
                    :prop="item.prop">
                    <template slot="header" v-if="item.prop === 'machiningName'">
                      <i style="color: #ff4949">*</i>
                      {{ item.label }}
                    </template>
                    <template slot-scope="scope">
                      <el-form-item :prop="`machiningItems[${scope.$index}].${item.prop}`"
                        :rules="rules[`machiningItems.${item.prop}`]">
                        <el-input v-model="scope.row[`${item.prop}`]" v-if="item.type=='input'" />
                        <el-checkbox v-model="scope.row[`${item.prop}`]" v-if="item.type=='check'" />
                      </el-form-item>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </template>
          </cardTitleCom>
        </el-form>
      </template>
      <template #buttonList>
        <el-button size="mini" @click="handleClose">取消</el-button>

        <el-button type="primary" size="mini" @click="submitForm">确定</el-button>
      </template>
    </c-dialog>
    <!-- 回收站 -->
    <Dialog2 :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import Dialog2 from '@/components/Dialog' //回收站
import cardTitleCom from '@/views/components/cardTitleCom'
import {
  addGoodsMachining, //新增
  updateGoodsMachining, //编辑
  getGoodsMachiningDetail, //详情
  getDelGoodsMachining,
  restoreGoodsMachining
} from '@/api/goods/goodsMachining' //接口api
import CDialog from '@/components/dialogTemplate/dialog/index.vue'
export default {
  name: 'MachiningDetail',
  components: {
    Dialog2,
    CDialog,
    cardTitleCom
  },
  props: {
    //开关
    open: {
      type: Boolean,
      default: false
    },
    //属性id
    machiningGroupId: {
      type: String
    }
  },
  watch: {
    //侦听是否打开弹窗  获取属性详情
    open: {
      async handler(val) {
        if (val) {
          this.close()
          if (this.machiningGroupId) {
            const res = await getGoodsMachiningDetail(this.machiningGroupId)
            this.form = res.data
          }
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      dialogOptions: {
        type: 'ShopInfo',
        show: false,
        title: '回收站',
        width: '75vw',
        data: {
          getListApi: getDelGoodsMachining,
          restoreListApi: restoreGoodsMachining,
          id: 'machiningId',
          getDataKey: { list: "data" },
          // search: [
          //   {
          //     type: 'filters',
          //     isRestore: true,
          //     model: '',
          //     filters: [
          //       { filter: 'query', label: '全部' },
          //       { filter: 'groupNames', label: '属性组名称' },
          //       { filter: 'names', label: '属性名称' }
          //     ]
          //   },
          //   {
          //     type: 'button',
          //     tip: '查询',
          //     btnType: 'primary',
          //     click: 'search',
          //     isRestore: true
          //   }
          // ],
          columns: [
            {
              prop: 'machiningName',
              label: '属性名称',
              minWidth: 180
            },
            {
              prop: 'machiningPrice',
              label: '加价(元)',
              minWidth: 180
            },
            {
              prop: 'isFreePack',
              label: '是否打包',
              minWidth: 80,
              formatter: (v, row) => row.isFreePack ? '是' : '否',
            },
            {
              prop: 'sortNo',
              label: '排序',
              minWidth: 100
            }
          ]
        }
      },
      tableCellLabel: '序号',
      //表格字段
      tableField: [
        { label: '属性名称', prop: 'machiningName', type: 'input' },
        { label: '加价(元)', prop: 'machiningPrice', type: 'input' },
        { label: '是否打包', prop: 'isFreePack', type: 'check' },
        { label: '排序', prop: 'sortNo', type: 'input' }
      ],
      // 表单
      form: {
        machiningGroupId: undefined, //属性id
        machiningGroupName: undefined, //属性组名称
        isEnableCheckBox: false, //是否启用属性值多选
        isRequired: false, //属性值是否必选
        //属性值数组
        machiningItems: [
          {
            machiningName: undefined, //属性名称
            machiningPrice: undefined, //加价(元)
            sortNo: undefined //排序
          }
        ]
      },
      //表单校验
      rules: {
        machiningGroupName: [
          { required: true, message: '请输入属性名称', trigger: 'blur' }
        ],
        'machiningItems.machiningName': [
          {
            required: true,
            message: '请输入属性值',
            trigger: ['blur', 'change']
          }
        ]
        // 'machiningItems.machiningPrice': [
        //   {
        //     required: true,
        //     message: '请输入加价金额',
        //     trigger: ['blur', 'change']
        //   },
        //   {
        //     pattern: /^\d+(\.\d+)?$/,
        //     message: '只能输入大于等于0的数字',
        //     trigger: ['blur', 'change']
        //   }
        // ]
      }
    }
  },
  methods: {
    huiShou() {
      this.dialogOptions.data.pagination = {
        machiningGroupId:
          this.machiningGroupId
      }
      this.dialogOptions.data.defaultBody = {
        machiningGroupId:
          this.machiningGroupId
      }
      this.dialogOptions.show = true
    },
    //还原刷新页面
    async handleEvent() {
      const res = await getGoodsMachiningDetail(this.machiningGroupId)
      this.form = res.data
    },
    //表格行hover时,显示操作加减号
    cellMouseEnter(row) {
      let eleIndex = this.form.machiningItems.indexOf(row)
      for (let index = 0; index < this.form.machiningItems.length; index++) {
        const element = this.form.machiningItems[index]
        if (eleIndex == index) {
          element['hoverRow'] = true
        } else {
          element['hoverRow'] = false
        }
      }
      this.tableCellLabel = '操作'
    },
    //表格行离开hover时,不显示操作加减号,显示序号
    cellMouseLeave() {
      // 移除hover的事件
      for (let index = 0; index < this.form.machiningItems.length; index++) {
        const element = this.form.machiningItems[index]
        element['hoverRow'] = false
      }
      this.tableCellLabel = '序号'
    },
    //关闭事件
    handleClose() {
      // //有值提示并初始化数据
      // let arr = [] //存放是否有值bool值
      // for (let key in this.form) {
      //   if (key !== 'machiningItems') {
      //     if (this.form[key]) arr.push(true)
      //   } else {
      //     const val = this.form[key].some(v => {
      //       let isExist = false
      //       for (let k in v) {
      //         if (v[k]) isExist = true
      //       }
      //       return isExist
      //     })
      //     arr.push(val)
      //   }
      // }
      // let bool = false //标识是否有输入了值
      // //当arr数值里有true 说明表单有值,弹出提示
      // bool = arr.some(v => v)
      // if (bool) {
      //   this.$confirm('内容已更改, 确定关闭?', '提示', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     type: 'warning'
      //   })
      //     .then(() => {
      //       //关闭对话框
      //       this.$emit('update:open', false)
      //       //初始化数据
      //       this.close()
      //     })
      //     .catch(() => {})
      // } else {
      //   this.$emit('update:open', false)
      // }
      this.$emit('update:open', false)
      //初始化数据
      this.close()
    },
    //表格增加/减少一行
    handleRow(name, index) {
      if (name === 'push') {
        this.form.machiningItems.splice(index + 1, 0, { isFreePack: false })
      } else {
        if (this.form.machiningItems.length <= 1) {
          this.$set(this.form.machiningItems, index, [])
          return
        }
        this.form.machiningItems.splice(index, 1)
      }
    },
    //重置数据
    close() {
      // 表单
      this.form = {
        machiningGroupId: undefined, //属性id
        machiningGroupName: undefined, //属性组名称
        isEnableCheckBox: false, //是否启用属性值多选
        isRequired: false, //属性值是否必选
        //属性值数组
        machiningItems: [
          {
            isFreePack: false, //是否包邮
            machiningName: undefined, //属性名称
            machiningPrice: undefined, //加价(元)
            sortNo: undefined //排序
          }
        ]
      }
      //重置/移除校验
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    //新增/编辑 提交事件
    submitForm() {
      this.$refs['form'].validate(valid => {
        //表单校验
        if (valid) {
          //属性id为undefined 为新增
          if (this.form.machiningGroupId === undefined) {
            addGoodsMachining(this.form).then(res => {
              this.$emit('update:open', false)
              this.$emit('getList')
              this.$message.success('新增成功')
              this.close()
            })
          } else {
            //修改
            updateGoodsMachining(this.form).then(res => {
              this.$emit('update:open', false)
              this.$emit('getList')
              this.$message.success('修改成功')
              this.close()
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep.el-form-item {
    margin-bottom: 0 !important;
  }
  ::v-deep .el-form-item__content {
    margin-left: 0 !important;
  }
  .main {
    padding: 0 20px;
    ::v-deep.el-form-item {
      margin-bottom: 10px !important;
    }
    //提示的文字样式
    .tag {
      margin-bottom: 0;
      margin-left: 102px;
      color: #a4a5a6;
      line-height: 25px;
    }
  }
  //表格区域
  .table {
    //使表格校验时显示，校验通过时隐藏
    ::v-deep .el-form-item {
      padding: 0;
      margin: 0;
    }
    ::v-deep .el-form-item__error {
      position: static;
    }
  }
</style>
