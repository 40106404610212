<template>
  <!-- 加值属性 -->
  <div class="wrap" id="wrap">
    <cardTitleCom cardTitle="查询" id="search-card">
      <template slot="cardContent">
        <div class="navSearch">
          <!-- 高级搜索按钮 -->
          <seniorSearch v-model="queryParams" :getList="getList" :dropDownList="dropDownList" :isAdvancedSearch="false"
            :isBillDate="false" :isSearchInput="true" isSearchInputTitle="输入属性组名称、属性名称" />
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom cardTitle="属性汇总" class="brandInfo">
      <template slot="cardContent">
        <div class="tableContent">
          <!-- 操作按钮 -->
          <operatingButton :getList="getList" :isRecycleBtn="true" :isDeleteBtn="false" :multiple="multiple"
            @handleAdd="handleAdd" @handleDelete="handleDelete" @handleRedoDelete="dialogOptions.show = true" />
          <el-table :data="tableData" tooltip-effect="dark" @selection-change="handleSelectionChange"
            @row-click="handleRowClick" border v-loading="loadingTable" ref="multipleTable" :max-height="tableHeight"
            :height="tableHeight" style="width: 100%" row-key="machiningGroupId">
            <el-table-column type="selection" width="50" align="center" fixed="left" />
            <el-table-column label="序号" align="center" type="index" width="50" fixed="left" />
            <el-table-column label="属性组名称" align="center" prop="machiningGroupName" show-overflow-tooltip width="400">
              <template slot-scope="scope">
                <el-link type="primary" @click.stop="handleUpdate(scope.row)" target="_blank">
                  {{ scope.row.machiningGroupName }}
                </el-link>
              </template>
            </el-table-column>
            <el-table-column label="属性名称" align="center" prop="machiningName" show-overflow-tooltip>
            </el-table-column>
            <el-table-column label="关联商品数" align="center" prop="machiningCount" show-overflow-tooltip width="250" />
          </el-table>
          <bottomPage v-model="queryParams" :getList="getList" :totalCom="total" @selectAllCom="selectAll"
            :idsCom="machiningGroupIds"></bottomPage>
        </div>
      </template>
    </cardTitleCom>
    <!-- 新增/编辑对话框 -->
    <machiningDetail :open.sync="OpenMachiningDetail" :machiningGroupId="machiningGroupId" @getList="getList">
    </machiningDetail>
    <!-- 回收站 -->
    <Dialog2 :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import comBtn from '@/views/components/com-btn' //按钮组件
import machiningDetail from './machiningDetail.vue' //新增/编辑对话框
import {
  listGoodsMachining, //分页列表
  delGoodsMachining, //删除
  restore //还原
} from '@/api/goods/goodsMachining' //接口api
import bottomPage from '@/views/components/bottomPage' //底部分页
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import operatingButton from '@/views/components/operatingButton' //操作按钮
import Dialog2 from '@/components/Dialog' //回收站
export default {
  name: 'Machining',
  components: {
    comBtn,
    machiningDetail,
    seniorSearch,
    bottomPage,
    cardTitleCom,
    operatingButton,
    Dialog2
  },
  data() {
    return {
      dialogOptions: {
        type: 'ShopInfo',
        show: false,
        title: '回收站',
        width: '75vw',
        data: {
          getListApi: listGoodsMachining,
          restoreListApi: restore,
          id: 'machiningGroupId',
          search: [
            {
              type: 'filters',
              isRestore: true,
              model: '',
              filters: [
                { filter: 'query', label: '全部' },
                { filter: 'groupNames', label: '属性组名称' },
                { filter: 'names', label: '属性名称' }
              ]
            },
            {
              type: 'button',
              tip: '查询',
              btnType: 'primary',
              click: 'search',
              isRestore: true
            }
          ],
          columns: [
            {
              prop: 'machiningGroupName',
              label: '属性组名称',
              minWidth: 180
            },
            {
              prop: 'machiningName',
              label: '属性名称',
              minWidth: 180
            },
            {
              prop: 'machiningCount',
              label: '关联商品数',
              minWidth: 100
            }
          ]
        }
      },
      multiple: true, //单独修改禁用
      //搜索框搜索的条件
      dropDownList: [
        { parameter: 'query', name: '全部' },
        { parameter: 'groupNames', name: '属性组名称' },
        { parameter: 'names', name: '属性名称' }
      ],
      //表格过渡动画
      loading: false,
      loadingTable: false, //表格加载
      machiningGroupId: undefined, //属性id
      tableData: [], //属性列表
      queryParams: {
        pageSize: 15, //页容量
        pageNum: 1, //页码
        name: undefined //属性名称
      },
      //选中的数据项
      selectData: [],
      //选中的数据的id
      machiningGroupIds: [],
      //列表总条数
      total: 0,
      OpenMachiningDetail: false, //打开新增/编辑对话框
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 40
    }
  },
  created() {
    this.getList()
  },
  computed: {
    tableHeight() {
      return this.vivwH - this.paginationH - this.searchH - 140
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    //还原刷新页面
    async handleEvent() {
      this.loading = true
      await this.getList()
      this.loading = false
    },
    handleResize() {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    },
    //点击行选中
    handleRowClick(row) {
      // 获取表格对象
      // 切换行选中状态
      this.$refs.multipleTable.toggleRowSelection(row)
    },
    // 新增按钮
    async handleAdd() {
      this.machiningGroupId = undefined
      this.OpenMachiningDetail = true
    },
    // 删除按钮
    async handleDelete() {
      if (this.machiningGroupIds.length <= 0)
        return this.$message.warning('请选择要执行的数据')
      this.$confirm(`确定删除所选的<span style="color: #1890ff;"> ${this.machiningGroupIds.length} </span>个加值属性吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        type: 'warning',
      })
        .then(() => {
          return delGoodsMachining(this.machiningGroupIds)
        })
        .then(() => {
          this.$modal.msgSuccess('删除成功')
          this.getList()
        })
        .catch(() => { })
    },

    //点击修改事件
    async handleUpdate(row) {
      this.machiningGroupId = row.machiningGroupId
      this.OpenMachiningDetail = true
    },
    //点击全选所有数据
    async selectAll(value) {
      if (value) {
        this.loadingTable = true
        const res = await listGoodsMachining({
          ...this.queryParams,
          pageNum: 1,
          pageSize: this.total
        })
        //重新渲染表格
        this.tableData = res.rows
        //表格多选
        this.tableData.map(row => {
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(row, value)
          })
        })
        this.loadingTable = false
      } else {
        //取消全选
        this.$nextTick(() => {
          this.$refs.multipleTable.clearSelection()
        })
        this.getList()
      }
    },
    //表格多选框发生变化时触发
    handleSelectionChange(selection) {
      this.selectData = selection
      this.machiningGroupIds = selection.map(item => item.machiningGroupId)
      this.multiple = !this.machiningGroupIds.length
    },
    //查询列表
    getList() {
      this.loadingTable = true
      listGoodsMachining(this.queryParams).then(res => {
        this.tableData = res.rows
        this.total = res.total
        this.loadingTable = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
//整体盒子
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 搜索
  .navSearch {
    padding: 10px 10px 10px 20px;
    //筛选条件
    .searchCriteria {
      padding-top: 10px;
      .selectFrameUp {
        display: inline-block;
        margin-right: 15px;
      }
    }
  }
  .brandInfo {
    height: calc(100% - 94px);
    //源单信息表格
    .tableContent {
      margin: 6px 10px 10px 10px;
    }
  }
}
</style>
